import React from "react"

export interface ValuesMobNavDataTypes {
    mobNav: boolean,
    setmobNav: React.Dispatch<React.SetStateAction<boolean>>
}
export const RootMobNavContext = React.createContext<ValuesMobNavDataTypes>({} as ValuesMobNavDataTypes);

export const RootMobNavProvider = ({ children }: { children: React.ReactNode }) => {
    const [mobNav, setmobNav] = React.useState<boolean>(false)

    return <RootMobNavContext.Provider value={{ mobNav, setmobNav, }}>
        {children}
    </RootMobNavContext.Provider>
}