import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { z } from 'zod'
import { ListProjectsSchema } from '../../schemas/ProjectsSchema'

const ProjectsSchemaModel = ListProjectsSchema

export interface ProjectsSlice {
  projects: z.infer<typeof ProjectsSchemaModel>,
}

const initialState: ProjectsSlice = {
  projects: [],
}

export const stacksSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {

    refreshprojects: (state, action: PayloadAction<z.infer<typeof ProjectsSchemaModel>>) => {
      state.projects = action.payload
    },
    clearprojects: (state) => {
      state.projects = []
    },

  },
})

// Action creators are generated for each case reducer function
export const { refreshprojects, clearprojects, } = stacksSlice.actions

export default stacksSlice.reducer