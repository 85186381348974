import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@radix-ui/themes/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Theme } from '@radix-ui/themes';
import { RootModalProvider } from './ctxs/modalContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { store } from './lib/redux/store';
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import { RootMobNavProvider } from './ctxs/mobNavContext';
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchInterval: 300000, refetchOnWindowFocus: 'always' } } })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Theme appearance="dark">
          <RootMobNavProvider>
            <RootModalProvider>
              <App />

              <Toaster />
            </RootModalProvider>
          </RootMobNavProvider>
        </Theme>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
