import React from 'react'
import { RiDiscordFill } from 'react-icons/ri'
import TitleSection from './TitleSection'
import Service from './Service'
import { useSelector } from 'react-redux'
import { RootState } from '../lib/redux/store'
import useMobNav from '../utils/hooks/uiHooks/useMobNav'

const Services = () => {

  const { mobNav,  } = useMobNav()
  const servicesData = useSelector((state: RootState) => state.services.services)

  return (
    <section id='servicesList' className={`max-w mx-auto ${mobNav && "scale-95"} transition-all max-[600px]:px-[1rem]`}>
      <TitleSection title='My Services' />
      <div className='flex items-center justify-center flex-wrap gap-[3rem] flex-row mt-[5rem] mb-10'>
        {
          servicesData.map((it, idX) => {
            return <Service it={it} keyx={idX} key={idX} />
          })
        }


        {
          servicesData.length === 0 && <p className="text-center mx-auto text-white/40 font-MontRegular">No service data to display at the moment.</p>
        }
      </div>
    </section>
  )
}

export default Services