
interface DataForm {
    email: string,
    telephone: string,
    description_project: string
}

export class MailerAPI {

    async send_mail(data: DataForm) {

        return fetch(
            "https://send-email-latoure.onrender.com/sendmail",
            {
                method: "POST",
                headers: {
                    'Content-Type': "application/json;charset=utf-8"
                },
                body: JSON.stringify(data)
            }
        )
            .then((js) => js.json())
            .then((res) => {
                return res;
            })

    }
}