import React from 'react'
import Button from './Button'
import { BsDiscord, BsLinkedin, BsMailbox2, } from "react-icons/bs";
import { BiLogoUpwork } from "react-icons/bi";

import { gsap } from 'gsap'

import { TextPlugin } from 'gsap/dist/TextPlugin'
import { useSelector } from 'react-redux';
import { RootState } from '../lib/redux/store';
import Splitting from "splitting";
import useMobNav from '../utils/hooks/uiHooks/useMobNav';

gsap.registerPlugin(TextPlugin);

const Hero = () => {
    const { mobNav, setmobNav } = useMobNav()

    const userIdentityData = useSelector((state: RootState) => state.userIdentity.userIdentity)

    React.useEffect(() => {
        userIdentityData?.mail && setTimeout(() => {
            var tl = gsap.timeline();

            const target = document.querySelector('#small_desc') as HTMLElement;
            Splitting({ target: target, by: 'words' });

            tl.to("#me", {
                top: 0,
                opacity: 1,
                duration: 2,
                ease: "Power3.easeOut"
            })
                .to("#small_desc", {
                    opacity: 1
                }, "<30%")

                .to("#who_iam", { duration: 7, text: userIdentityData?.who_iam, ease: "Power3.easeInOut" }, "-=25%")
                .to("#small_desc .word", {
                    y: 0,
                    rotateX: 0,
                    rotateY: 0,
                    rotateZ: 0,
                    opacity: 1,
                    stagger: 0.05, // 0.1 seconds between when each ".box" element starts animating
                }, "<35%")
                .to("#mail", {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    ease: "Power3.easeOut",
                }, "<20%")
                .to("#upwork", {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    ease: "Power3.easeOut",
                }, "<20%")
                .to("#linked", {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    ease: "Power3.easeOut",
                }, "<20%")
                .to("#discord", {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    ease: "Power3.easeOut",
                }, "<20%")
                .to("#download_my_cv", {
                    bottom: 0,
                    opacity: 1,
                    duration: 2,
                    ease: "Power3.easeOut",
                }, "<30%")
        }, 1000)
    }, [userIdentityData])

    return (
        <section id="hero" className={`flex max-w ${mobNav && "scale-95"} transition-all max-[1000px]:px-[1rem] max-[400px]:px-[.1rem] mx-auto max-h-[900px] relative flex-row max-[800px]:mt-[4vh] mt-[2.5rem] mb-[7rem] max-md:min-h-[500px] items-center justify-center flex-wrap py-[5rem]`}>

            <div className="w-full text-center">
                <div id='me' className='rounded-full px-4 top-[-5rem] opacity-0 py-1 bg-[var(--secondary-light-color)] w-fit text-[var(--darker-color)] relative text-center mx-auto'>
                    <span className=' text-[.8rem] text-white font-MontRegular'>Hi I'm {userIdentityData?.first_name} <span className="font-MontSemiBold">{userIdentityData?.last_name}</span>.</span>
                    <a
                        target='_blank'
                        href={userIdentityData?.linkedin_link!}>
                        <img className='absolute bottom-[80%] -left-[30%] z-10 shadow-2xl rounded-t-full rounded-bl-full h-[3rem] c_shad magic_animate'
                            src={userIdentityData?.image!}
                            alt="my picture" />
                    </a>
                </div>
                <h2 id='who_iam' className='text-[4rem] text-white font-MontBold mt-10 max-[1100px]:text-[3.5rem] 
                max-[870px]:text-[3rem] max-[700px]:text-[2.7rem] max-[650px]:text-[2.355rem] max-[550px]:text-[2.2rem] max-[470px]:text-[1.95rem]  
                max-[1100px]:w-full max-[1100px]:px-[.5rem] max-[600px]:px-[.1rem] max-[400px]:px-[0] 
                mx-auto font-MontSemiBold'></h2>
                <p id='small_desc' className='leading-[1.6rem] font-MontRegular w-2/3 max-[780px]:w-[80vw] max-[580px]:w-[90vw] max-[580px]:px-[1rem] mx-auto text-[var(--text-secondary-color-light-2)] text-[.9rem]'>{userIdentityData?.description}</p>

                <div className="flex-wrap flex mt-14 w-2/4 max-[650px]:w-full max-[650px]:px-[1rem] mx-auto justify-center items-center gap-5">
                    <a id='mail' href={`mailto:${userIdentityData?.mail}`} className="flex gap-2 items-center translate-y-[2rem] opacity-0 transition-all active:scale-95 hover:text-[var(--secondary-light-color)]
                        border-[1px] py-1 px-4 rounded-full font-MontRegular
                        border-white/10 hover:border-[var(--secondary-light-color-2)] text-[var(--text-secondary-color)]">
                        <BsMailbox2 size={15} />
                        <p className='text-[.8rem]'>E-mail</p>
                    </a>
                    <a id='upwork' target='_blank' href={userIdentityData?.upwork_link!} className="flex gap-2 items-center translate-y-[2rem] opacity-0 transition-all active:scale-95 font-MontRegular hover:text-[var(--secondary-light-color)]
                     border-[1px] py-1 px-4 rounded-full
                     border-white/10 hover:border-[var(--secondary-light-color-2)] text-[var(--text-secondary-color)]">
                        <BiLogoUpwork size={15} />
                        <p className='text-[.8rem]'>Upwork</p>
                    </a>

                    <a id='linked' target='_blank' href={userIdentityData?.linkedin_link!} className="flex gap-2 items-center translate-y-[2rem] opacity-0 transition-all active:scale-95 font-MontRegular hover:text-[var(--secondary-light-color)]
                     border-[1px] py-1 px-4 rounded-full
                     border-white/10 hover:border-[var(--secondary-light-color-2)] text-[var(--text-secondary-color)]">
                        <BsLinkedin size={15} />
                        <p className='text-[.8rem]'>Linkedin</p>
                    </a>

                    <a id='discord' target='_blank' href={userIdentityData?.discord_link!} className="flex gap-2 items-center translate-y-[2rem] opacity-0 transition-all active:scale-95 font-MontRegular hover:text-[var(--secondary-light-color)]
                        border-[1px] py-1 px-4 rounded-full
                        border-white/10 hover:border-[var(--secondary-light-color-2)] text-[var(--text-secondary-color)]">
                        <BsDiscord size={15} />
                        <p className='text-[.8rem]'>Discord</p>
                    </a>
                </div>

                <div id='download_my_cv' className="mt-14 opacity-0 bottom-[1rem] justify-center flex">
                    <Button
                        href={`${userIdentityData?.cv_link}`}
                        text='Download my CV'
                        without_sh />
                </div>

            </div>
        </section>
    )
}

export default Hero