import React, { useRef } from 'react'
import TitleSection from './TitleSection';
import { AiFillStar } from 'react-icons/ai';
import useOnScrollAnimations from '../utils/hooks/uiHooks/useTimeLine';
import useMobNav from '../utils/hooks/uiHooks/useMobNav';

const Experiences = () => {

    const { mobNav,  } = useMobNav()
    const { experiencesData, experiencesRef } = useOnScrollAnimations()

    return (
        <section ref={experiencesRef} id='experiences' className={`max-w ${mobNav && "scale-95"} transition-all mx-auto max-[750px]:px-[2rem] my-[10rem]`}>
            <TitleSection title='My experiences' />

            <div id='experiences-timeline' className="mx-auto max-[750px]:max-w-[450px] mt-[5rem] pt-[5rem] gap-[2rem] relative">

                <div id='elementId' className={`absolute top-0 max-[750px]:left-0 left-1/2 -translate-x-1/2 w-[3px] rounded-full h-0 ${experiencesData.length && "bg-[var(--secondary-light-color)]"} z-10`}>
                    <div className={`absolute top-0 left-1/2 z-0 -translate-x-1/2 w-4 h-4 rounded-full ${experiencesData.length && "border-[1px] border-[var(--secondary-light-color)] bg-[var(--third-color)]"}`}></div>
                    <div className={`absolute bottom-0 z-0 left-1/2 -translate-x-1/2 w-4 h-4 rounded-full ${experiencesData.length && "border-[1px] border-[var(--secondary-light-color)] bg-[var(--third-color)]"}`}></div>
                </div>

                {
                    experiencesData.length > 0 &&
                    <>

                        {
                            experiencesData.map((it, idX) => {
                                return <div key={idX} className={`timeline-container ${it.position.toLowerCase()}`}>
                                    <div className={`period ${it.position.toLowerCase()} p-2 border-[1px] shadow_primary border-[var(--secondary-light-color)] rounded-full bg-[var(--third-color)]`}>
                                        <AiFillStar color='white' />
                                    </div>
                                    <div className="py-8 px-12 max-[750px]:px-2">
                                        <h2 className="font-MontSemiBold text-[var(--secondary-light-color)] text-[1.2rem] uppercase mb-[1.5rem] border-b-[1px] border-gray-600/20 pb-4">{it.period}</h2>
                                        <div className="text-[.88rem] text-white/80 leading-[1.8rem] font-MontRegular">
                                            <ul>
                                                {
                                                    it.descriptions.map((dt, idY) => {
                                                        return <li key={idY}>{dt}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </>
                }
            </div>

            {
                experiencesData.length === 0 && <p className="text-center mx-auto text-white/40 font-MontRegular">No experiences data to display at the moment.</p>
            }
        </section>
    )
}

export default Experiences