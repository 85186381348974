import { configureStore } from '@reduxjs/toolkit'
import stacksSlice from './slices/stacksSlice'
import experiencesSlice from './slices/experiencesSlice'
import projectsSlice from './slices/projectsSlice'
import servicesSlice from './slices/servicesSlice'
import userIdentitySlice from './slices/userIdentitySlice'

export const store = configureStore({
    reducer: {
        stacks: stacksSlice,
        experiences: experiencesSlice,
        projects: projectsSlice,
        services: servicesSlice,
        userIdentity: userIdentitySlice,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch