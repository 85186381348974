import { z } from "zod";

export const ExperiencesSchema = z.object({
    $collectionId: z.string(),
    $createdAt: z.string(),
    $databaseId: z.string(),
    $updatedAt: z.string(),
    $permissions: z.array(z.any()).min(0),
    $id: z.string(),
    period: z.string(),
    position: z.string(),
    descriptions: z.array(z.string()).min(0),
})

export const ListExperiencesSchema = z.array(ExperiencesSchema).min(0)