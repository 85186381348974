import React from 'react'
import { databases } from '../../../lib/appwrite';
import { z } from 'zod';
import { useQuery } from 'react-query';
import { ListStacksSchema } from '../../../lib/schemas/StacksSchema';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../lib/redux/store';
import { refreshstacks } from '../../../lib/redux/slices/stacksSlice';

const useStacks = () => {
    const dispatcher = useDispatch()

    const FetchStacks = async () => {
        const response = await databases.listDocuments(`${process.env.REACT_APP_DB_INSTANCE_KEY}`, `${process.env.REACT_APP_STACKS_KEY}`);
        return response
    }

    const { data: Stacks, } = useQuery({
        queryKey: ['StacksData',],
        queryFn: FetchStacks,
        retry: true, retryDelay: 2000
    })

    React.useEffect(() => {
        ListStacksSchema.safeParse(Stacks?.documents).success && dispatcher(refreshstacks([...Stacks?.documents as z.infer<typeof ListStacksSchema>]))
    }, [Stacks])

    return;
}

export default useStacks