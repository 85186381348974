import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { useOutsideClick } from '../utils/hooks/uiHooks/useOutSideClick';
import useModal from '../utils/hooks/uiHooks/useModal';

import { motion } from "framer-motion"
import IMG1 from "../assets/images/img11.jpg"
import { ModalData } from '../ctxs/modalContext';

const Modal = () => {
    const { modalDetails, setmodalDetails, modalData, setmodalData } = useModal()

    const ref = useOutsideClick(() => {
        setmodalDetails(!modalDetails)
        setmodalData({} as ModalData)
    });

    return (
        <>
            <AnimatePresence>
                {modalDetails && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className='bg-black/60 blured sm w-screen h-screen fixed p-2 z-50 inset-0 flex items-center justify-center'
                    >
                        <div
                            ref={ref}
                            className="rounded-md p-5 max-[500px]:p-3 bg-[var(--darker-color)] shadow-2xl relative w-full max-w-[420px]">
                            <div className="border-b-[1px] border-white/20 pb-5 gap-3 flex-wrap">
                                <h2 className="text-[1rem] text-white text-center font-MontBold">Details Project</h2>
                            </div>
                            <div id='modal-ctx' className="max-h-[60vh] max-[500px]:px-[.5rem] px-[1rem] min-h-[1500px]:max-h-[800px] my-2 py-3 overflow-y-auto">
                                <div className="w-full h-[15rem] shadow-2xl overflow-hidden rounded-sm mb-5">
                                    <img
                                        src={modalData.image}
                                        className='w-full h-full select-none object-cover'
                                        alt="" />
                                </div>
                                <div className="mt-4">
                                    <h2 className="text-[.8rem] text-white/80 mb-4 font-MontSemiBold underline">Title:</h2>
                                    <h3 className="text-[1rem] text-white mb-2 font-MontSemiBold">NFT APP</h3>
                                    <h2 className="text-[.8rem] text-white/80 mb-4 font-MontSemiBold underline">Description:</h2>
                                    <p className='text-[var(--text-secondary-color-light-2)] font-MontRegular text-[.85rem] leading-[1.3rem]'>{modalData.description}</p>
                                </div>
                                <div className="mt-[1rem]">
                                    <h2 className="text-[.8rem] text-white/80 mb-4 font-MontSemiBold underline">Main role played:</h2>
                                    <ul className="text-[var(--text-secondary-color-light-2)] text-[.85rem] leading-[1.3rem] font-MontRegular text- .list__">

                                        {
                                            modalData.roles?.map((it: string, idX) => {
                                                return <li key={idX} className='relative pl-5 after:absolute after:top-[.5rem] mb-2 after:left-0 after:w-1 after:h-1 after:bg-white after:rounded-full pr-[1rem] '>{it}</li>
                                            })
                                        }
                                        {
                                            !Boolean(modalData?.roles) && <p className="text-white">Not defined</p>
                                        }
                                        {/* <li className='relative pl-5 after:absolute after:top-[.5rem] mb-2 after:left-0 after:w-1 after:h-1 after:bg-white after:rounded-full pr-[1rem]'>5 cups chopped Porcini mushrooms.At magni molestias mollitia ea neque fuga totam hic,
                                            ipsa vero repellat enim excepturi ad.</li> */}
                                    </ul>
                                </div>
                                <div className="mt-[1rem]">
                                    <h2 className="text-[.8rem] text-white/80 mb-4 font-MontSemiBold underline">Technology(ies)/tool(s) used</h2>
                                    <div className="flex gap-2 flex-wrap text-[.8rem] items-center font-MontRegular mt-5">
                                        {
                                            modalData.stacks?.map((it: string) => {
                                                return <div className="bg-[var(--secondary-light-color-2)] shadow-2xl py-1 p-2 rounded-md text-white">{it}</div>
                                            })
                                        }

                                        {
                                            !Boolean(modalData?.stacks) && <p className="text-white">Not defined</p>
                                        }
                                        {/* <div className="bg-[var(--secondary-light-color-2)] shadow-2xl py-1 p-2 text-[.8rem] rounded-md text-white font-MontRegular">React</div>
                                        <div className="bg-[var(--secondary-light-color-2)] shadow-2xl py-1 p-2 text-[.8rem] rounded-md text-white font-MontRegular">Rasa</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="border-t-[1px] border-white/20 pt-5  flex items-center justify-end gap-3 flex-wrap">
                                <div className="px-[1rem]">
                                    <div
                                        onClick={() => setmodalDetails(!modalDetails)}
                                        className="bg-white shadow-2xl py-2 p-3 text-[.8rem] rounded-md text-black font-MontSemiBold cursor-pointer">Close</div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default Modal