import React from 'react'
import { databases } from '../../../lib/appwrite';
import { useQuery } from 'react-query';
import { useDispatch, } from 'react-redux';
import { ListProjectsSchema } from '../../../lib/schemas/ProjectsSchema';
import { refreshprojects } from '../../../lib/redux/slices/projectsSlice';
import { z } from 'zod';

const useProjects = () => {
    const dispatcher = useDispatch()

    const FetchProjects = async () => {
        const response = await databases.listDocuments(`${process.env.REACT_APP_DB_INSTANCE_KEY}`, `${process.env.REACT_APP_PROJECTS_KEY}`);
        return response
    }

    const { data: Projects, } = useQuery({
        queryKey: ['ProjectsData',],
        queryFn: FetchProjects,
        retry: true, retryDelay: 2000
    })

    React.useEffect(() => {
        ListProjectsSchema.safeParse(Projects?.documents).success && dispatcher(refreshprojects([...Projects?.documents as z.infer<typeof ListProjectsSchema>]))
    }, [Projects])

    return;
}

export default useProjects