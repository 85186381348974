import React from 'react'
import { RootModalsContext } from '../../../ctxs/modalContext'

const useModal = () => {
    
    const modalContext = React.useContext(RootModalsContext)
    const { modalDetails, setmodalDetails, modalData, setmodalData } = modalContext

    return {
        modalDetails,
        modalData,
        setmodalDetails,
        setmodalData
    }
}

export default useModal