import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { z } from 'zod'
import { UserIdentitySchema } from '../../schemas/UserIdentitySchema'


export interface UserIdentitySlice {
  userIdentity: z.infer<typeof UserIdentitySchema> | null,
}

const initialState: UserIdentitySlice = {
  userIdentity: null,
}

export const userIdentitySlice = createSlice({
  name: 'userIdentity',
  initialState,
  reducers: {

    refreshUserIdentity: (state, action: PayloadAction<z.infer<typeof UserIdentitySchema>>) => {
      state.userIdentity = action.payload
    },
    clearUserIdentity: (state) => {
      state.userIdentity = null
    },

  },
})

// Action creators are generated for each case reducer function
export const { refreshUserIdentity, clearUserIdentity, } = userIdentitySlice.actions

export default userIdentitySlice.reducer