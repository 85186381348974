import React from 'react'
import useModal from './useModal'

const useDeactivateScrollOnModal = () => {

    const { modalDetails, } = useModal()
    React.useEffect(() => {
        modalDetails && (() => {
            const body = document.body;
            body.style.overflowY = "hidden"
        })()

        !modalDetails && (() => {
            const body = document.body;
            body.style.overflowY = "scroll"
        })()
    }, [modalDetails])

    return;
}

export default useDeactivateScrollOnModal