import React from 'react'
import { RootMobNavContext } from '../../../ctxs/mobNavContext'

const useMobNav = () => {
    const mobNavContext = React.useContext(RootMobNavContext)
    const { mobNav, setmobNav } = mobNavContext

    return {
        mobNav, setmobNav
    }
}

export default useMobNav