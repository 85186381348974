import { z } from "zod";

export const ProjectsSchema = z.object({
    $collectionId: z.string(),
    $createdAt: z.string(),
    $databaseId: z.string(),
    $id: z.string(),
    $permissions: z.array(z.any()).min(0),
    $updatedAt: z.string(),
    description: z.string(),
    image: z.string(),
    roles: z.array(z.string()),
    stacks: z.array(z.string()),
    title: z.string(),
})

export const ListProjectsSchema = z.array(ProjectsSchema).min(0)