import React, { useRef } from 'react'
import { RootState } from '../../../lib/redux/store';
import { useSelector } from 'react-redux';

const useOnScrollAnimations = () => {

    const experiencesRef = useRef(null);
    const experiencesData = useSelector((state: RootState) => state.experiences.experiences)

    React.useEffect(() => {

        let lastPos = { top: 0, left: 0, x: 0, y: 0, diff: 0, activated: false, lastHeight: 0, parentHeight: 0 };

        function applyHeight() {
            const lV = document.querySelector('#elementId') as HTMLElement;

            try {
                const formated = window.getComputedStyle(lV!).getPropertyValue('height')
                lastPos.lastHeight = parseInt(formated.split("px")[0])
                const newHeightToSet = (parseInt(formated.split("px")[0]) + lastPos.diff).toString()
                lV.style.height = newHeightToSet + "px"
            } catch (error) {
                return;
            }
        }

        const animateTimeLine = () => {

            const lineParent = document.querySelector('#experiences-timeline') as Element;
            const lineParentHeight = window.getComputedStyle(lineParent!).getPropertyValue('height')

            lastPos.parentHeight = parseInt(lineParentHeight.split("px")[0])

            const lineElement = document.querySelector('#elementId') // Y
            const lineElementBoundingRect = lineElement?.getBoundingClientRect() // Y
            const exp = Array.from(document.querySelectorAll('.timeline-container') as NodeListOf<HTMLElement>)   // ?.getBoundingClientRect().top
            const parentBoundingClientRect = lineParent.getBoundingClientRect().top

            let options = {
                root: null,
                rootMargin: "0px",
                threshold: .15,
            };

            lastPos.diff = window.scrollY - lastPos.top

            lastPos.activated && (lastPos.lastHeight <= (lastPos.parentHeight)) && applyHeight();

            exp.forEach((et) => {
                const xpBounding = et?.getBoundingClientRect().top;

                let expPos = (xpBounding! - parentBoundingClientRect)
                let lineBoundingPos = (lineElementBoundingRect?.bottom! - parentBoundingClientRect)
                let lineIsIntersecting = lineBoundingPos >= expPos;

                if (lineIsIntersecting) {
                    et.classList.add("activated")
                } else {
                    et.classList.remove("activated")
                }
            })

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {

                    if (entry.isIntersecting) {
                        lastPos.activated = true
                    } else {
                        lastPos.activated = false
                    }
                });
            }, options);

            observer.observe(experiencesRef.current!);
            lastPos.top = window.scrollY
        }


        const animatePortfolio = function () {

            const folios_items = document.querySelectorAll("#folios-items") as NodeListOf<HTMLElement>
            const folios_items_parent = document.querySelector("#portfolio") as HTMLElement

            let options = {
                root: null,
                rootMargin: "0px",
                threshold: .3,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {

                    if (entry.isIntersecting) {
                        folios_items.forEach((it) => {
                            it.style.opacity = "1";
                            it.style.marginTop = "0px";
                        })
                    }
                });
            }, options);

            observer.observe(folios_items_parent);
        }


        const animateServices = function () {

            const services = document.querySelectorAll("#services") as NodeListOf<HTMLElement>
            const servicesListParent = document.querySelector("#servicesList") as HTMLElement

            let options = {
                root: null,
                rootMargin: "0px",
                threshold: .3,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {

                    if (entry.isIntersecting) {
                        services.forEach((it) => {
                            it.style.opacity = "1";
                            it.style.marginTop = "0px";
                        })
                    }
                });
            }, options);

            observer.observe(servicesListParent);
        }

        const animateStacks = function () {

            const stack_items = document.querySelectorAll("#stack_item") as NodeListOf<HTMLElement>
            const stacksParent = document.querySelector("#stacks") as HTMLElement

            let options = {
                root: null,
                rootMargin: "0px",
                threshold: .3,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {

                    if (entry.isIntersecting) {
                        stack_items.forEach((it) => {
                            it.style.opacity = "1";
                            it.style.marginTop = "0px";
                            it.style.transform = "scale(1)";
                        })
                    }
                });
            }, options);

            observer.observe(stacksParent);
        }

        const animateContact = function () {

            const contact = document.querySelector("#contact-form") as HTMLElement
            const footer = document.querySelector("#footer") as HTMLElement

            let options = {
                root: null,
                rootMargin: "0px",
                threshold: .3,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {

                    if (entry.isIntersecting) {
                        contact.style.opacity = "1";
                        contact.style.marginTop = "0px";
                    } else {
                        contact.style.opacity = "0";
                        contact.style.marginTop = "2rem";
                    }
                });
            }, options);

            observer.observe(footer);
        }

        window.onscroll = function (e) {
            animateTimeLine()
            animatePortfolio()
            animateServices()
            animateStacks()
            animateContact()
        };

        window.onresize = function (e) {
            animateTimeLine()
        };
    }, [experiencesData])


    return {
        experiencesRef, experiencesData
    }
}

export default useOnScrollAnimations