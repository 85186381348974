import Name from './Name'
import { RootState } from '../lib/redux/store'
import { useSelector } from 'react-redux'
import Contact from './Contact'

const Footer = () => {
    const userIdentityData = useSelector((state: RootState) => state.userIdentity.userIdentity)
    return (
        <section id="footer">

            <div id='ctx' className='mt-5 pb-1rem relative'>
                <div className="mx-auto max-w">
                    <div className="flex flex-row items-center mb-12 gap-5 max-[1260px]:gap-[5rem] max-[1260px]:flex-wrap max-[1260px]:justify-center justify-between">
                        <div className="w-1/2 max-[1070px]:w-full max-[1070px]:px-[2.5rem] max-[1260px]:text-center">
                            <div className="">
                                <h2 className="text-[2.5rem] text-white font-MontSemiBold">It all start with
                                    <span className='text-[var(--secondary-light-color)] font-MontRegular ml-2'>👋</span>
                                </h2>
                                <div className="mt-6 mb-10 font-MontRegular">
                                    <p className="text-[.92rem] mb-3 text-[var(--text-secondary-color)]">Let's work together!</p>
                                    <p className="text-[.92rem] mt-2 text-[var(--text-secondary-color)]">To get started, please fill out the form as detailed as possible.I will respond withing 48h hours.</p>
                                    {/* <p className="text-[.92rem] text-[var(--text-secondary-color)]"></p> */}
                                    <p className="text-[.92rem] mt-3 text-[var(--text-secondary-color)]">Alternatively, you can contact me at <a href={`mailto:${userIdentityData?.mail}`} className='underline text-[var(--secondary-light-color)]'>{userIdentityData?.mail}</a></p>
                                </div>
                            </div>
                            <div className="max-[1070px]:w-fit max-[1070px]:mx-auto">
                                <div className="w-fit max-[1260px]:mx-auto">
                                    <Name />
                                </div>
                                <div className="mt-8 flex flex-row max-[1260px]:justify-center flex-wrap gap-4">
                                    <a href="#hero" id="navigator" className='text-[.85rem] font-MontSemiBold 
                                    font-MontRegular text-white/80'>Home</a>
                                    <a href="#portfolio" id="navigator" className='text-[.85rem] font-MontSemiBold 
                                    font-MontRegular text-white/80'>Portfolio</a>
                                    <a href="#about" id="navigator" className='text-[.85rem] font-MontSemiBold 
                                    font-MontRegular text-white/80'>About</a>
                                    <a href="#services" id="navigator" className='text-[.85rem] font-MontSemiBold 
                                    font-MontRegular text-white/80'>Services</a>
                                    <a href="#stacks" id="navigator" className='text-[.85rem] font-MontSemiBold 
                                    font-MontRegular text-white/80'>Skills</a>
                                    <a href="#experiences" id="navigator" className='text-[.85rem] font-MontSemiBold 
                                    font-MontRegular text-white/80'>Experiences</a>
                                </div>
                            </div>
                        </div>

                        <div className="w-1/2 max-[1070px]:mx-auto max-[1070px]:w-[90vw] max-w-[485px]">
                            <Contact />
                        </div>
                    </div>

                    <div className='flex mt-[8rem] flex-wrap gap-[2rem] font-MontRegular py-[1.5rem] max-[800px]:px-[1rem] border-t-[1px] border-[var(--default-light-color)] justify-between items-center'>
                        <div className="flex items-center flex-wrap text-[.9rem] gap-4">
                            <a href="#" className='underline text-[var(--text-secondary-color)]'>Privacy Policy</a>
                            <a href="#" className='underline text-[var(--text-secondary-color)]'>Terms of Service</a>
                            <a href="#" className='underline text-[var(--text-secondary-color)]'>Cookies Settings</a>
                        </div>
                        <div className="text-[.9rem] text-[var(--text-secondary-color)]">
                            @2023 Motta. All right reserved.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer