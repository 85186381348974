import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { z } from 'zod'
import { ListStacksSchema } from '../../schemas/StacksSchema'

const StacksSchemaModel = ListStacksSchema

export interface StackSlice {
  stacks: z.infer<typeof StacksSchemaModel>,
}

const initialState: StackSlice = {
  stacks: [],
}

export const stacksSlice = createSlice({
  name: 'stacks', 
  initialState,
  reducers: {

    refreshstacks: (state, action: PayloadAction<z.infer<typeof StacksSchemaModel>>) => {
      state.stacks = action.payload
    },
    clearstacks: (state) => {
      state.stacks = []
    },

  },
})

// Action creators are generated for each case reducer function
export const { refreshstacks, clearstacks, } = stacksSlice.actions

export default stacksSlice.reducer