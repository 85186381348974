import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../lib/redux/store'

const Name = ({ xxl }: { xxl?: boolean }) => {
    const userIdentityData = useSelector((state: RootState) => state.userIdentity.userIdentity)
    return (
        <h3 className={`${xxl ? "text-[1.5rem] shadow-xl px-[1rem]" : "text-[1.1rem] px-[.8rem]"} text-white font-MontExtraBold border-[2px] w-fit flex items-center justify-center border-[var(--secondary-light-color)] rounded-full`}>{userIdentityData?.first_name.toUpperCase()[0]}.{userIdentityData?.last_name.toUpperCase()[0]}</h3>
    )
}

export default Name