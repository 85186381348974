import React from 'react'
import Name from './Name'
import Button from './Button'
import toast from 'react-hot-toast'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MailerAPI } from '../utils/hooks/SendMail'

type Inputs = {
  email: string,
  telephone: string,
  description_project: string
}

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = (data) => requestSendEmail(data)

  const notify = (type: 'success' | 'error', msg: string) => type === 'error' ? toast.error(msg) : toast.success(msg);

  const requestSendEmail = async (inputs: Inputs) => {
    try {
      let mailerAPI = new MailerAPI()
      let response = await mailerAPI.send_mail(inputs)
      if (response?.id) {
        notify("success", "Your mail has been sent.")
        reset({ description_project: "", email: "", telephone: "" })
      }

    } catch (error) {
      console.log("error", error)
      notify("error", "Oops an error has occurred.")
    }
  }

  return (
    <div id='contact-form' className="bg-[var(--darker-color)] p-9 w-full border-[1px] shadow_secondary border-[var(--secondary-light-color-3)] rounded-2xl">

      <div className="mb-10">
        <Name xxl />
      </div>

      <div className="">
        <p className='text-[.85rem] text-white font-MontSemiBold mb-3'>Email</p>
        <div id='input-parent' className={`rounded-full mb-[2rem] bg-transparent font-MontRegular w-full  transition-all border-[var(--default-light-color)]`}>
          <input
            autoComplete="off"
            {...register("email", { required: true })}
            type="text"
            placeholder={"Enter your email"}
            className={`rounded-lg outline-none bg-transparent h-full py-[1rem] px-5 w-full text-[.95rem] text-[var(--text-secondary-color)]`}></input>
        </div>
      </div>

      <div className="">
        <p className='text-[.85rem] text-white font-MontSemiBold mb-3'>Phone number</p>
        <div id='input-parent' className={`rounded-full mb-[2rem] bg-transparent font-MontRegular w-full  transition-all border-[var(--default-light-color)]`}>
          <input
            autoComplete="off"
            {...register("telephone", { required: true })}
            type="number"
            placeholder={"Enter your phone number"}
            className={`rounded-lg outline-none bg-transparent h-full py-[1rem] px-5 w-full text-[.95rem] text-[var(--text-secondary-color)]`}></input>
        </div>
      </div>

      <div className="">
        <p className='text-[.85rem] text-white font-MontSemiBold mb-3'>Message</p>
        <div id='input-parent' className={`rounded-lg mb-[2rem] bg-transparent font-MontRegular w-full  transition-all border-[var(--default-light-color)]`}>
          <textarea
            {...register("description_project", { required: true })}
            placeholder={"Enter your message"}
            rows={3}
            className={`rounded-lg outline-none bg-transparent h-full py-[1rem] px-5 w-full text-[.95rem] text-[var(--text-secondary-color)]`}></textarea>

        </div>
      </div>

      <p className='text-[.7rem] text-[var(--text-secondary-color)] font-MontRegular -mt-1'>By subscribing you agree to with our Privacy Policy</p>

      <div className="mt-5 w-fit">
        <Button
          action={handleSubmit(onSubmit)}
          text='Submit the form' />
      </div>
    </div>
  )
}

export default Contact