import React from 'react';
import './App.scss';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Stacks from './components/Stacks';
import About from './components/About';
import Services from './components/Services';
import Footer from './components/Footer';
import Experiences from './components/Experiences';
import Portfolio from './components/Portfolio';
import useDynamiqueNavs from './utils/hooks/uiHooks/useDynamiqueNavs';
import Modal from './components/Modal';
import useDeactivateScrollOnModal from './utils/hooks/uiHooks/useDeactivateScrollOnModal';
import useStacks from './utils/hooks/controllerHooks/useStacks';
import useExperiences from './utils/hooks/controllerHooks/useExperiences';
import useProjects from './utils/hooks/controllerHooks/useProjects';
import useServices from './utils/hooks/controllerHooks/useServices';
import useUserIdentity from './utils/hooks/controllerHooks/useUserIdentity';
import MobileNavs from './components/MobileNavs';



function App() {
  useDynamiqueNavs({ parent: "section", childs: "#nLinks", mobChilds: "#mbNavs" })
  useDeactivateScrollOnModal()
  useStacks()
  useExperiences()
  useProjects()
  useServices()
  useUserIdentity()

  return (
    <div className="relative">
      <Modal />
      <MobileNavs />
      <div id='rootContainer'>
        <div className="g1"></div>
        <div className="g2"></div>
        <Navigation />
        <div className="overflow-hidden">
          <Hero />
          <Portfolio />
          <About />
          <Services />
          <Stacks />
          <Experiences />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
