import React from 'react'
import Button from './Button'

import AVATAR from "../assets/images/avatar2.jpeg"
import { RiDiscordFill, RiFacebookCircleLine, RiLinkedinFill, RiTwitterFill } from "react-icons/ri";
import { useSelector } from 'react-redux';
import { RootState } from '../lib/redux/store';
import { BsMailbox, BsMailbox2 } from 'react-icons/bs';
import { BiLogoUpwork } from 'react-icons/bi';
import useMobNav from '../utils/hooks/uiHooks/useMobNav';

const About = () => {
  const { mobNav, setmobNav } = useMobNav()
  const userIdentityData = useSelector((state: RootState) => state.userIdentity.userIdentity)
  return (
    <section id='about' className={`max-w ${mobNav && "scale-95"} transition-all mx-auto max-[1000px]:px-[1rem]`}>
      <div className="rounded-3xl shadow-2xl border-[1px] border-white/10">
        <div id='about_bg' className="rounded-3xl transition-all max-[765px]:pb-[3rem] shadow-xl flex max-[765px]:flex-wrap max-[765px]:gap-[3rem] gap-2 max-[765px]:p-6 items-center overflow-hidden p-10">
          <div className="w-1/2 max-[765px]:w-full max-[765px]:order-1 max-[765px]:pr-0 max-[765px]:pl-0 pr-10 pl-4">
            <h3 className='text-[2.5rem] text-white max-[765px]:text-center font-MontBold'>About Me</h3>
            <p className="text-sm max-[765px]:text-center text-[var(--text-secondary-color)] mt-3 leading-[1.6rem] font-MontRegular">{userIdentityData?.about_me}</p>
            <div className="w-fit max-[765px]:mx-auto mt-8 max-[850px]:gap-[2rem] flex gap-2 items-center flex-row flex-wrap">
              <div className="max-[765px]:mx-auto">
                <Button target href='#footer' text="Let's work together" />
              </div>
              <div className="flex max-[765px]:mx-auto flex-wrap gap-2 ">
                <a href={userIdentityData?.discord_link!} target='_blank' className="rounded-full bg-white active:scale-95 transition-all p-2">
                  <RiDiscordFill
                    color='black'
                    size={20}
                  />
                </a>
                <a target='_blank' href={userIdentityData?.upwork_link!} className="rounded-full bg-white active:scale-95 transition-all p-2">
                  <BiLogoUpwork
                    color='black'
                    size={20}
                  />
                </a>
                <a
                  href={userIdentityData?.linkedin_link!}
                  target='_blank'
                  className="rounded-full bg-white active:scale-95 transition-all p-2">
                  <RiLinkedinFill
                    color='black'
                    size={20}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="w-1/2 max-[765px]:order-0 max-[765px]:w-full min-h-[450px] h-full rounded-lg shadow-2xl bg-[var(--secondary-light-color)]">

          </div>
        </div>
      </div>

      <div className='about'>
        <div className="text-center">
          <h2 className='text-[2.5rem] text-white font-MontExtraBold'>{userIdentityData?.years_of_xp}</h2>
          <p className='text-[1rem] font-MontRegular text-[var(--text-secondary-color)]'>Year of experiences</p>
        </div>
        <div className="text-center">
          <h2 className='text-[2.5rem] text-white font-MontExtraBold'>{userIdentityData?.nb_client_served}</h2>
          <p className='text-[1rem] font-MontRegular text-[var(--text-secondary-color)]'>Clients Served</p>
        </div>
        <div className="text-center">
          <h2 className='text-[2.5rem] text-white font-MontExtraBold'>{userIdentityData?.nb_recommandation}</h2>
          <p className='text-[1rem] font-MontRegular text-[var(--text-secondary-color)]'>Recommandations</p>
        </div>
        <div className="text-center">
          <h2 className='text-[2.5rem] text-white font-MontExtraBold'>{userIdentityData?.nb_projects}</h2>
          <p className='text-[1rem] font-MontRegular text-[var(--text-secondary-color)]'>Projects of clients</p>
        </div>
      </div>
    </section>
  )
}

export default About