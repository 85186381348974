import React from 'react'
import { BiChevronRight } from 'react-icons/bi'
import useModal from '../utils/hooks/uiHooks/useModal'
import { z } from 'zod'
import { ProjectsSchema } from '../lib/schemas/ProjectsSchema'

const ItemPortfolio = ({ it, keyx }: { it: z.infer<typeof ProjectsSchema>, keyx: number }) => {

    const { setmodalDetails, modalDetails, setmodalData } = useModal()
    return (
        <div id='folios-items' style={{ "--iteration": `${(keyx + 1) * 300}ms` } as React.CSSProperties}>
            <div
                className="bg-[var(--darker-color)] shadow-lg folio-items overflow-hidden max-w-[325px] min-w-[325px] rounded-2xl">
                <div className="w-full h-[15rem]">
                    <img
                        src={it.image}
                        className='w-full h-full select-none object-cover'
                        alt="" />
                </div>
                <div className="mt-3 p-[.5rem] pb-[1.5rem] px-[1.2rem]">
                    <h2 className="text-[1rem] text-white mb-2 font-MontSemiBold">{it.title}</h2>
                    <p className='text-[var(--text-secondary-color)] font-MontRegular text-[.85rem] leading-[1.3rem]'>{it.description}
                    </p>
                    <div onClick={() => {
                        setmodalDetails(!modalDetails)
                        setmodalData({ description: it.description, image: it.image, roles: it.roles, stacks: it.stacks })
                    }} className="mt-[1rem] active:scale-90 transition-all cursor-pointer w-fit flex items-center bg-[var(--secondary-light-color-2)] 
                            shadow-2xl py-1 p-2 text-[.8rem] rounded-md text-white font-MontRegular">
                        <div className="">See More</div>
                        <BiChevronRight size={18} color='white' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemPortfolio