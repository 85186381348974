import React from 'react'
import useMobNav from './useMobNav';

const useDynamiqueNavs = (targets: { parent: string, childs: string, mobChilds: string }) => {

    const { mobNav, setmobNav } = useMobNav()
    React.useEffect(() => {
        window.scrollTo(0, 0);
        let sections: NodeListOf<HTMLElement> = document.querySelectorAll(targets.parent)
        let navLinks = document.querySelectorAll(targets.childs)
        let mobNavLinks = document.querySelectorAll(targets.mobChilds)

        const load = () => {
            sections.forEach(sec => {
                let top = window.scrollY;
                let offset = sec.offsetTop
                let id = sec.getAttribute("id")
                let smaller_espace_to_next = (top - offset)

                if (
                    // top > offset && top <= offset + height
                    ((smaller_espace_to_next > -200) && (smaller_espace_to_next < 100))
                ) {
                    navLinks.forEach((link) => {
                        link.classList.remove("activated")
                        document.querySelector(targets.childs + " a[href*=" + id + "]")?.parentElement!
                            .classList.add("activated")
                    })

                    mobNavLinks.forEach((link) => {
                        link.classList.remove("activated")
                        document.querySelector(targets.mobChilds + " a[href*=" + id + "]")?.parentElement!
                            .classList.add("activated")
                    })

                }
            })
        }

        window.addEventListener("scroll", load)

        return () => {
            window.removeEventListener('scroll', load);
        };
    }, [])

    return;
}

export default useDynamiqueNavs