import React from "react"

export type ModalData = { description: string, roles: string[], stacks: string[], image: string }

export interface ValuesModalsDataTypes {
    modalDetails: boolean,
    modalData: ModalData,
    setmodalData: React.Dispatch<React.SetStateAction<ModalData>>,
    setmodalDetails: React.Dispatch<React.SetStateAction<boolean>>
}
export const RootModalsContext = React.createContext<ValuesModalsDataTypes>({} as ValuesModalsDataTypes);

export const RootModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [modalDetails, setmodalDetails] = React.useState<boolean>(false)
    const [modalData, setmodalData] = React.useState<ModalData>({} as ModalData)

    return <RootModalsContext.Provider value={{ modalDetails, setmodalDetails, modalData, setmodalData }}>
        {children}
    </RootModalsContext.Provider>
}