import { z } from "zod";

export const UserIdentitySchema = z.object({
    $collectionId: z.string(),
    $createdAt: z.string(),
    $databaseId: z.string(),
    $updatedAt: z.string(),
    $permissions: z.array(z.any()).min(0),
    $id: z.string(),
    image: z.nullable(z.string()),
    upwork_link: z.nullable(z.string()),
    linkedin_link: z.nullable(z.string()),
    discord_link: z.nullable(z.string()),
    description: z.string(),
    mail: z.string(),
    cv_link: z.string(),
    about_me: z.string(),
    years_of_xp: z.number(),
    nb_client_served: z.number(),
    nb_recommandation: z.number(),
    nb_projects: z.number(),
    first_name: z.string(),
    last_name: z.string(),
    who_iam: z.string(),
})

export const ListsUserIdentitySchema = z.array(UserIdentitySchema).min(0)