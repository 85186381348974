import React from 'react'
import { databases } from '../../../lib/appwrite';
import { z } from 'zod';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ListsUserIdentitySchema, UserIdentitySchema } from '../../../lib/schemas/UserIdentitySchema';
import { refreshUserIdentity } from '../../../lib/redux/slices/userIdentitySlice';

const useUserIdentity = () => {
    const dispatcher = useDispatch()

    const FetchUserIdentity = async () => {
        const response = await databases.listDocuments(`${process.env.REACT_APP_DB_INSTANCE_KEY}`, `${process.env.REACT_APP_DB_USERIDENTITY_KEY}`);
        return response
    }

    const { data: userIdentityResponse, } = useQuery({
        queryKey: ['UserIdentityData'],
        queryFn: FetchUserIdentity,
        retry: true, retryDelay: 2000,
    })

    React.useEffect(() => {
        console.log(userIdentityResponse?.documents)
        ListsUserIdentitySchema.safeParse(userIdentityResponse?.documents).success && dispatcher(refreshUserIdentity(userIdentityResponse?.documents.filter(it => it.mail === "nazairedayo.dev@gmail.com")[0] as unknown as z.infer<typeof UserIdentitySchema>))
    }, [userIdentityResponse])

    return;
}

export default useUserIdentity