import { z } from "zod";

export const ServicesSchema = z.object({
    $collectionId: z.string(),
    $createdAt: z.string(),
    $databaseId: z.string(),
    $updatedAt: z.string(),
    $permissions: z.array(z.any()).min(0),
    $id: z.string(),
    title: z.string(),
    description: z.string(),
})

export const ListsServicesSchema = z.array(ServicesSchema).min(0)