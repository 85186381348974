import React from 'react'
import Name from './Name'
import FormControl from './FormControl'
import Button from './Button'

import IMG1 from "../assets/images/img11.jpg"
import IMG2 from "../assets/images/img2.png"
import IMG3 from "../assets/images/img3.png"
import IMG4 from "../assets/images/img4.png"
import IMG5 from "../assets/images/img5.png"
import IMG6 from "../assets/images/img6.png"
import IMG7 from "../assets/images/img7.png"
import IMG8 from "../assets/images/img8.png"
import IMG9 from "../assets/images/img9.png"
import IMG10 from "../assets/images/img10.png"

import TitleSection from './TitleSection'
import { BiChevronRight } from 'react-icons/bi'
import ItemPortfolio from './ItemPortfolio'
import { useSelector } from 'react-redux'
import { RootState } from '../lib/redux/store'
import useAnimateDragHorizontal from '../utils/hooks/uiHooks/useAnimateDragHorizontal'
import useMobNav from '../utils/hooks/uiHooks/useMobNav'

const Portfolio = () => {

    const images = [
        IMG1,
        IMG2,
        IMG3,
        IMG4,
        IMG5,
        IMG6,
        IMG7,
        IMG8,
        IMG9,
        IMG10,
    ]

    const { mobNav, setmobNav } = useMobNav()
    useAnimateDragHorizontal("#folio-carousel")

    const projectsData = useSelector((state: RootState) => state.projects.projects)

    return (
        <section id='portfolio' className={`mb-[8rem] ${mobNav && "scale-95"} transition-all mt-[1.5rem]`}>
            <TitleSection title='My lastest projets' />
            <div id='folio-carousel' className="overflow-x-auto w-full flex max-[500px]:gap-[1rem] gap-[2rem] mt-[5rem]">
                {projectsData.map((it, idX) => {
                    return <ItemPortfolio
                        it={it}
                        keyx={idX}
                        key={idX} />
                })}
                <div className="w-wrapped"></div>
            </div>

            {
                projectsData.length === 0 && <p className="text-center mx-auto text-white/40 font-MontRegular">No projects data to display at the moment.</p>
            }

        </section>
    )
}

export default Portfolio