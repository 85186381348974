import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { z } from 'zod'
import { ListsServicesSchema } from '../../schemas/ServicesSchema'

const ServicesSchemaModel = ListsServicesSchema

export interface Serviceslice {
  services: z.infer<typeof ServicesSchemaModel>,
}

const initialState: Serviceslice = {
  services: [],
}

export const ServicesSlice = createSlice({
  name: 'Services',
  initialState,
  reducers: {

    refreshservices: (state, action: PayloadAction<z.infer<typeof ServicesSchemaModel>>) => {
      state.services = action.payload
    },
    clearservices: (state) => {
      state.services = []
    },

  },
})

// Action creators are generated for each case reducer function
export const { refreshservices, clearservices, } = ServicesSlice.actions

export default ServicesSlice.reducer