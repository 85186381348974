import React from 'react'
import { MdMiscellaneousServices } from 'react-icons/md'
import { z } from 'zod'
import { ServicesSchema } from '../lib/schemas/ServicesSchema'

const Service = ({ it, keyx }: { it: z.infer<typeof ServicesSchema>, keyx: number }) => {
    return (
        <div id="services" style={{ "--iteration": `${(keyx + 1) * 150}ms` } as React.CSSProperties} className='rounded-3xl p-10 shadow_primary border-[1px] border-[var(--secondary-light-color-2)]'>
            <div className="flex flex-rol items-center gap-4">
                <div className="">
                    <MdMiscellaneousServices
                        color='white'
                        size={30}
                    />
                    <h3 className='text-[1.7rem] mt-5 font-MontSemiBold'>{it.title}</h3>
                    <p className="text-sm text-white/60 leading-[1.6rem] mt-5 font-MontRegular">{it.description}</p>
                </div>
            </div>
        </div>
    )
}

export default Service