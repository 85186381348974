import { z } from "zod";

export const StackSchema = z.object({
    $collectionId: z.string(),
    $createdAt: z.string(),
    $databaseId: z.string(),
    $updatedAt: z.string(),
    $permissions: z.array(z.any()).min(0),
    $id: z.string(),
    name: z.string(),
    image: z.string(),
})

export const ListStacksSchema = z.array(StackSchema).min(0)