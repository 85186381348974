import React from 'react'
import { useQuery } from 'react-query';
import { useDispatch, } from 'react-redux';
import { z } from 'zod';
import { ListsServicesSchema } from '../../../lib/schemas/ServicesSchema';
import { refreshservices } from '../../../lib/redux/slices/servicesSlice';
import { databases } from '../../../lib/appwrite';

const useServices = () => {
    const dispatcher = useDispatch()

    const FetchServices = async () => {
        const response = await databases.listDocuments(`${process.env.REACT_APP_DB_INSTANCE_KEY}`, `${process.env.REACT_APP_SERVICES_KEY}`);
        return response
    }

    const { data: Services, } = useQuery({
        queryKey: ['ServicesData'],
        queryFn: FetchServices,
        retry: true, retryDelay: 2000,
    })

    React.useEffect(() => {
        ListsServicesSchema.safeParse(Services?.documents).success && dispatcher(refreshservices([...Services?.documents as unknown as z.infer<typeof ListsServicesSchema>]))
    }, [Services])

    return;
}

export default useServices