import React from 'react'
import Button from './Button'
import { useSelector } from 'react-redux'
import { RootState } from '../lib/redux/store'
import { Select } from '@radix-ui/themes'
import Name from './Name'
import { AiOutlineClose } from 'react-icons/ai'
import useMobNav from '../utils/hooks/uiHooks/useMobNav'
import { AnimatePresence } from 'framer-motion'

const MobileNavs = () => {
    const { mobNav, setmobNav } = useMobNav()
    const userIdentityData = useSelector((state: RootState) => state.userIdentity.userIdentity)
    return (

        <div
            className={`fixed top-0 transition-all ${!mobNav ? "opacity-0 right-[-100%]" : "right-0 opacity-100"} flex flex-col items-center justify-between p-16 px-12 z-[100] shadow-2xl h-screen blured bg-[var(--third-color-transparent)] min-[1100px]:hidden`}>
            <Name />
            <div
                onClick={() => setmobNav(!mobNav)}
                className=" border-[2px] border-[var(--secondary-light-color)] p-2 rounded-full absolute top-[1.5rem] left-[1.5rem]  transition-all active:scale-95">
                <AiOutlineClose color='white' />
            </div>
            <ul className="px-[1rem]">
                <li onClick={() => setmobNav(!mobNav)} id="mbNavs" className='mobNav_item text-[.9rem]'><a href="#hero">Home</a></li>
                <li onClick={() => setmobNav(!mobNav)} id="mbNavs" className='mobNav_item text-[.9rem]'><a href="#portfolio">Portfolio</a></li>
                <li onClick={() => setmobNav(!mobNav)} id="mbNavs" className='mobNav_item text-[.9rem]'><a href="#about">About</a></li>
                <li onClick={() => setmobNav(!mobNav)} id="mbNavs" className='mobNav_item text-[.9rem]'><a href="#servicesList">Services</a></li>

                <li onClick={() => setmobNav(!mobNav)} id="mbNavs" className='mobNav_item text-[.9rem]'><a href="#stacks">Skills</a></li>
                <li onClick={() => setmobNav(!mobNav)} id="mbNavs" className='mobNav_item text-[.9rem]'><a href="#experiences">Experiences</a></li>
                <li onClick={() => setmobNav(!mobNav)} id="mbNavs" className='mobNav_item text-[.9rem]'><a href="#footer">Contact</a></li>
            </ul>

            <div className="nav-action flex items-center justify-center gap-4 flex-col  max-[330px]:hidden">

                {/* <div className="">
                    <Select.Root size="1" defaultValue="fr">
                        <Select.Trigger variant="classic" color='blue' radius="full" />
                        <Select.Content color='blue'>
                            <Select.Group>
                                <Select.Label>Langues</Select.Label>
                                <Select.Item value="fr">FR</Select.Item>
                                <Select.Item value="en">EN</Select.Item>
                            </Select.Group>
                        </Select.Content>
                    </Select.Root>
                </div> */}

                {
                    userIdentityData?.cv_link && <Button
                        href={`${userIdentityData?.cv_link}`}
                        text='Download my CV'
                        without_sh />
                }

            </div>
        </div>

    )
}

export default MobileNavs