import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { z } from 'zod'
import { ListExperiencesSchema } from '../../schemas/ExperiencesSchema'

const experiencesSchemaModel = ListExperiencesSchema

export interface experienceslice {
  experiences: z.infer<typeof experiencesSchemaModel>,
}

const initialState: experienceslice = {
  experiences: [],
}

export const experiencesSlice = createSlice({
  name: 'experiences',
  initialState,
  reducers: {

    refreshexperiences: (state, action: PayloadAction<z.infer<typeof experiencesSchemaModel>>) => {
      state.experiences = action.payload
    },
    clearexperiences: (state) => {
      state.experiences = []
    },

  },
})

// Action creators are generated for each case reducer function
export const { refreshexperiences, clearexperiences, } = experiencesSlice.actions

export default experiencesSlice.reducer