import React from 'react'
import TitleSection from './TitleSection'
import { useSelector } from 'react-redux'
import { RootState } from '../lib/redux/store'
import useMobNav from '../utils/hooks/uiHooks/useMobNav'


const Stacks = () => {
  const { mobNav,  } = useMobNav()
  const stacksData = useSelector((state: RootState) => state.stacks.stacks)

  return (
    <section id='stacks' className={`mb-10 max-w ${mobNav && "scale-95"} transition-all mx-auto mt-[10rem]`}>
      <TitleSection title='My Stacks' />
      <div className="flex mt-[5rem] px-[1.25rem] max-[1100px]:w-full mx-auto items-center justify-center max-[1100px]:gap-[4rem] gap-[3.5rem] flex-wrap">

        {
          stacksData.map((it, idX) => {
            return <div id='stack_item' key={idX} style={{ "--iteration": `${(idX + 1) * 100}ms` } as React.CSSProperties} className="bg-[var(--darker-color)] stack-items rounded-full p-4 border-[2px] border-slate-700/20 transition-all">

              <div className="stack-items-info px-2">
                <p>{it.name}</p>
              </div>
              <img src={it.image}
                alt={it.name}
                className='w-[5rem] h-[5rem] rounded-full object-cover' />
            </div>
          })
        }

        {
          stacksData.length === 0 && <p className="text-center mx-auto text-white/40 font-MontRegular">No stacks data to display at the moment.</p>
        }
      </div>
    </section>
  )
}

export default Stacks