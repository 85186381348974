import React from 'react'
import { databases } from '../../../lib/appwrite';
import { z } from 'zod';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ListExperiencesSchema } from '../../../lib/schemas/ExperiencesSchema';
import { refreshexperiences } from '../../../lib/redux/slices/experiencesSlice';

const useExperiences = () => {
    const dispatcher = useDispatch()

    const FetchExperiences = async () => {
        const response = await databases.listDocuments(`${process.env.REACT_APP_DB_INSTANCE_KEY}`, `${process.env.REACT_APP_EXPERIENCES_KEY}`);
        return response
    }

    const { data: Experiences, } = useQuery({
        queryKey: ['ExperiencesData',],
        queryFn: FetchExperiences,
        retry: true, retryDelay: 2000
    })

    React.useEffect(() => {
        ListExperiencesSchema.safeParse(Experiences?.documents).success && dispatcher(refreshexperiences([...Experiences?.documents as z.infer<typeof ListExperiencesSchema>]))
    }, [Experiences])

    return;
}

export default useExperiences