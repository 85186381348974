import React from 'react'
import Button from './Button'
import Name from './Name'
import { AiOutlineMenu, } from "react-icons/ai";
import NAZAIRE_CV from "../assets/CV/CV_NAZAIRE_ADJAKOUN.pdf"
import { Select } from '@radix-ui/themes';
import { useSelector } from 'react-redux';
import { RootState } from '../lib/redux/store';
import useMobNav from '../utils/hooks/uiHooks/useMobNav';


const Navigation = () => {
    const { mobNav, setmobNav } = useMobNav()
    const userIdentityData = useSelector((state: RootState) => state.userIdentity.userIdentity)
    return (
        <div className='sticky flex items-center justify-center flex-wrap gap-4 z-50 top-[1rem] mx-auto max-nav'>
            <div className="nav blured">
                <ul className="nav-items ">
                    <li id="nLinks" className='text-[.9rem]'><a href="#hero">Home</a></li>
                    <li id="nLinks" className='text-[.9rem]'><a href="#portfolio">Portfolio</a></li>
                    <li id="nLinks" className='text-[.9rem]'><a href="#about">About</a></li>
                    <li id="nLinks" className='text-[.9rem]'><a href="#servicesList">Services</a></li>
                </ul>
                <div className="nav-name">
                    <Name />
                </div>
                <ul className="nav-items">
                    <li id="nLinks" className='text-[.9rem]'><a href="#stacks">Skills</a></li>
                    <li id="nLinks" className='text-[.9rem]'><a href="#experiences">Experiences</a></li>
                    <li id="nLinks" className='text-[.9rem]'><a href="#footer">Contact</a></li>
                </ul>
                <div className="flex gap-5 items-center cursor-pointer max-[1100px]:pr-3">
                    <div className="nav-action max-[330px]:hidden">
                        <Button
                            href={`${userIdentityData?.cv_link}`}
                            text='Download my CV'
                            without_sh />
                    </div>
                    <div onClick={() => setmobNav(!mobNav)} className="min-[1100px]:hidden h-[30px] flex items-center justify-center w-[30px] transition-all active:scale-95">
                        <AiOutlineMenu color='white' />
                    </div>
                </div>
            </div>
            {/* <div className="">

                <Select.Root size="1" defaultValue="fr">
                    <Select.Trigger variant="classic" color='blue' radius="full" />
                    <Select.Content color='blue'>
                        <Select.Group>
                            <Select.Label>Langues</Select.Label>
                            <Select.Item value="fr">FR</Select.Item>
                            <Select.Item value="en">EN</Select.Item>
                        </Select.Group>
                    </Select.Content>
                </Select.Root>
            </div> */}
        </div>
    )
}

export default Navigation