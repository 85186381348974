import React from 'react'

const Button = ({ text, without_sh, sm, href, target, action = () => { } }: { target?: boolean, action?: () => void, text: string, without_sh?: boolean, sm?: boolean, href?: string }) => {

    return (
        <>
            {
                href ?
                    <a href={href} target={!target ? '_blank' : "_top"} id='btn' className={`${sm ? "py-[.15rem]" : "py-[.5rem]"} px-4 min-h-[2rem] items-center 
        flex justify-center bg-[var(--secondary-light-color)] 
        cursor-pointer active:scale-95 transition-all text-white font-MontRegular rounded-full`}>
                        <p className={`${sm ? "text-[.65rem]" : "text-[.85rem]"} font-MontSemiBold`}>{text}</p>
                    </a>
                    :
                    <div
                        onClick={action}
                        id='btn' className={`${sm ? "py-[.15rem]" : "py-[.5rem]"} px-4 min-h-[2rem] items-center 
        flex justify-center bg-[var(--secondary-light-color)] 
        cursor-pointer active:scale-95 transition-all text-white font-MontRegular rounded-full`}>
                        <p className={`${sm ? "text-[.65rem]" : "text-[.85rem]"} font-MontSemiBold`}>{text}</p>
                    </div>
            }
        </>
    )
}

export default Button